import client from "@/services/client";
import addWeeks from "date-fns/addWeeks";
import endOfWeek from "date-fns/endOfWeek";
import getUnixTime from "date-fns/getUnixTime";
import startOfDay from "date-fns/startOfDay";
import startOfWeek from "date-fns/startOfWeek";

export const fetchAsyncDriverInfo = async (token, supplier_id) => {
  const params = {
    token,
    supplier_id,
  };
  const res = await client.appApiOnwheelUtility.get(
    `/metatruck/get_child_driver_info`,
    { params }
  );
  const photos = await client.appApiMetatruck.get(
    `/supplier/children/${supplier_id}/documents`,
    { params }
  ).catch(() => ({ data: [] }));
  return {
    ...res.data,
    photo: photos.data,
  };
};

export const fetchAsyncDriverStatistics = async (token, supplier_id) => {
  const now = new Date();
  const startOfToday = startOfDay(now)
  const startOfThisWeek = startOfWeek(startOfToday, { weekStartsOn: 1 })
  const from_time = getUnixTime(addWeeks(startOfThisWeek, -5));
  const to_time = getUnixTime(endOfWeek(startOfThisWeek, { weekStartsOn: 1 }));

  const res = await client.appApiMetatruck.get(
    `/supplier/children/${supplier_id}/statistics-v2`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: { from_time, to_time },
    }
  )

  return res.data;
}
