<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div class="container profile-chart">
        <div class="sticky">
          <CustomTopBar :parentModal="$parent">
            <div class="content">Chi tiết doanh thu</div>
          </CustomTopBar>
        </div>
        <div class="text">
          <p class="name">Tx. {{ detail.driver_name }}</p>
          <p class="plate">{{ detail.driver_plate_number }}</p>
        </div>
        <BoardChart v-if="scoreData" :scoreData="items[0]" />

        <b-carousel-list
          class="chart-carousel"
          v-model="carousel"
          :data="items"
          :items-to-show="1"
          :animated="animated"
          :autoplay="autoPlay"
          :arrow-hover="false"
          :repeat="repeat"
        >
          <template #item="list">
            <ChartDisplayItem :data="list" />
          </template>
        </b-carousel-list>

        <div class="bottom-text">ĐƠN THEO NGÀY</div>
      </div>
    </section>
  </div>
</template>

<script>
import { format, endOfWeek, getDay, addMonths } from "date-fns";
import vi from "date-fns/locale/vi";
import CustomTopBar from "@/common/components/CustomTopBar";
import BoardChart from "@/pages/Profile/components/ChartProfile/Board";
import ChartDisplayItem from "@/pages/Profile/components/ChartProfile/ChartDisplayItem";
import { fetchAsyncDriverStatistics } from "@/pages/Profile/services/driverService";
import startOfWeek from "date-fns/startOfWeek";

export default {
  name: "DetailProfile",
  components: { CustomTopBar, BoardChart, ChartDisplayItem },
  props: ["detail", "token"],
  data() {
    return {
      carousel: 0,
      animated: "slide",
      autoPlay: false,
      indicator: false,
      repeat: false,
      scoreData: "",
      items: [],
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      const data = await fetchAsyncDriverStatistics(this.token, id);
      this.items = this.mapData(data);
      this.scoreData = this.items[0];
      this.carousel = this.items.length - 1;
    }
  },
  methods: {
    mapData(data) {
      const week_stats = [];
      for (const daily_stat of data.daily_stats) {
        const startOfThisWeek = startOfWeek(new Date(daily_stat.time_range), { weekStartsOn: 1 });
        let indexWeek = week_stats.findIndex(
          (item) => item.period.getTime() === startOfThisWeek.getTime()
        );

        if (indexWeek === -1) {
          indexWeek = week_stats.push({
            period: startOfThisWeek,
            periodString: this.getPeriodTitle(startOfThisWeek),
            acceptance_rate: data.acceptance_rate,
            cancellation_rate: data.cancellation_rate,
            rating: data.rating,
            online_time: 0,
            totalFee: 0,
            totalOrder: 0,
            chartData: [],
          }) - 1;
        }

        const week_stat = week_stats[indexWeek];

        week_stat.totalFee += daily_stat.revenue;
        week_stat.totalOrder += daily_stat.orders;
        week_stat.online_time += daily_stat.online_time;
        week_stat.active_time = this.getOnlineTime(week_stat.online_time / 3600);

        const dateIndex = getDay(new Date(daily_stat.time_range));
        const label = this.convertKeyDate(dateIndex);
        week_stat.chartData.push({
          dateIndex,
          label,
          period: daily_stat.period,
          total_order: daily_stat.orders,
        });

        week_stats[indexWeek] = week_stat;
      }

      return week_stats;
    },
    convertKeyDate(index) {
      switch (index) {
        case 0:
          return "SU";
        case 1:
          return "MO";
        case 2:
          return "TU";
        case 3:
          return "WE";
        case 4:
          return "TH";
        case 5:
          return "FR";
        case 6:
          return "SA";
        default:
          return "";
      }
    },
    getPeriodTitle(dateObj) {
      const firstMonth = format(dateObj, "MM", { locale: vi });
      const firstDay = format(dateObj, "dd");
      const nextMonthObj = addMonths(dateObj, 1);
      const nextMonth = format(nextMonthObj, "MM", { locale: vi });

      const lastDay = format(
        new Date(endOfWeek(dateObj, { weekStartsOn: 1 })),
        "dd"
      );

      if (parseInt(firstDay) > parseInt(lastDay)) {
        return `Doanh thu ${firstDay}/${firstMonth} - ${lastDay}/${nextMonth}`;
      } else {
        return `Doanh thu ${firstDay}/${firstMonth} - ${lastDay}/${firstMonth}`;
      }
    },
    getOnlineTime(active_time) {
      if (active_time === 0) {
        return 0;
      } else if (active_time < 1) {
        const minute = Math.floor(active_time * 60);
        return `${minute}'`;
      } else {
        const hour = Math.floor(active_time);
        const minute = Math.floor((active_time - hour) * 60);
        return `${hour}h${minute.toString().padStart(2, "0")}'`;
      }
    },
  },
};
</script>

<style lang="scss">
.name {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #121619;
  margin-bottom: 4px;
}
.plate {
  font-size: 16px;
  line-height: 24px;
  color: #4d5358;
}
.profile-chart {
  .text {
    margin-bottom: 24px;
    margin-top: 64px;
  }
}
.chart-carousel {
  box-shadow: none !important;
  .carousel-arrow {
    .icon {
      top: 50px;
      color: #7a8189;
      &:hover {
        border: none;
      }
      i:before {
        font-size: 48px;
      }
    }
    .has-icons {
      &-right {
        right: 16px;
      }
      &-left {
        left: 16px;
      }
    }
  }
}
.bottom-text {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #a2a9b0;
  margin-top: 4px;
}
</style>
