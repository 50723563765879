<template>
  <div class="stack col stock-load">
    <slot></slot>
    <div class="label">{{ label }}</div>
    <button
      v-if="!isShow"
      class="btn btn-load circle"
      :disabled="loading || isBtnDisabled"
      @click="clicked"
    >
      <div class="head-icon">
        <span v-if="loading" class="loading-icon"></span>
        <span v-else class="icon"> <inline-svg :src="startIcon" /></span>
      </div>
      <span class="text">{{ btnLabel }}</span>
    </button>
    <StockMessage v-if="isShow">
      {{ msg }}
      <span class="time">{{ time }}</span>
    </StockMessage>
  </div>
</template>

<script>
import { CloseIcon, DoubleCheckIcon, StartIcon } from "@/common/icons";
import StockMessage from "@/pages/Stock/StockMessage";

export default {
  name: "StockItemLoad",
  components: {
    StockMessage,
  },
  props: {
    label: String,
    btnLabel: String,
    msg: String,
    loading: Boolean,
    isShow: Boolean,
    time: String,
    isBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeIcon: CloseIcon,
      startIcon: StartIcon,
      doubleCheckIcon: DoubleCheckIcon,
    };
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.stack {
  display: flex;
  flex-direction: row;
  gap: 8px;
  &.col {
    flex-direction: column;
  }
}

.label {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0 !important;
}

.circle {
  border-radius: 50px;
}

.btn-load {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 12px 4px 0;
  cursor: pointer;
  gap: 8px;
  .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
  }
}

.time {
  color: #007cff;
}

.loading-icon {
  &::after {
    content: "";
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 1px solid #fff;
    border-radius: 50%;
    border-right-color: #007cff;
    border-top-color: #007cff;
    display: block;
    width: 24px;
    height: 24px;
    border-width: 0.25em;
  }
}
</style>
