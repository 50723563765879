<template>
  <div class="container dashboard">
    <Header />
    <div class="analytic__wrapper">
      <Calendar @date-range="getDateRange($event)" />
      <Board
        :income="managerInfo.total_income"
        :vehicle="managerInfo.total_child_supplier"
        :orders="managerInfo.total_orders"
      />
    </div>
    <Divider />
    <Table :drivers="drivers" :loading="loading" />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import Divider from "@/common/components/CustomDivider";
import Header from "@/pages/Reporting/components/Header";
import Calendar from "@/pages/Reporting/components/Calendar/Calendar";
import Board from "@/pages/Reporting/components/Board";
import Table from "@/pages/Reporting/components/Table/Table";
import {
  fetchAsyncSupplierParentStatistic,
} from "@/pages/Reporting/services/reportService";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

export default {
  name: "Reporting",
  components: { Header, Calendar, Board, Divider, Table },
  computed: {
    ...mapState(["token"]),
  },
  data() {
    return {
      dateRange: "",
      managerInfo: "",
      drivers: "",
      supplierToken: "",
      loading: false,
    };
  },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  mounted() {
    // this.supplierToken = this.token;
    this.supplierToken = this.$route.query.supplier_token;
    this.actionSetSupplierToken({ value: this.supplierToken });
    this.getSupplierParentStatistic(this.supplierToken);
  },
  methods: {
    ...mapActions("supplierList", ["actionSetSupplierToken"]),
    getDateRange($event) {
      this.dateRange = $event;
      const from_time = startOfDay(new Date($event[0]));
      const end_time = endOfDay(new Date($event[1]));
      this.getSupplierParentStatistic(this.supplierToken, from_time, end_time);
    },
    async getSupplierParentStatistic(token, from_time, to_time) {
      const data = await fetchAsyncSupplierParentStatistic(
        token, from_time, to_time
      );

      const managerInfo = {
        total_income: 0,
        total_orders: 0,
        self_total_income: 0,
        self_total_orders: 0,
        total_revenue: 0,
        total_child_supplier: 0,
      }

      for (const childData of data.data || []) {
        managerInfo.total_child_supplier += 1;
        managerInfo.total_income += childData.total_income;
        managerInfo.total_orders += childData.total_orders;
        managerInfo.self_total_income += childData.self_total_income;
        managerInfo.self_total_orders += childData.self_total_orders;
        managerInfo.total_revenue += childData.total_revenue;
      }

      this.managerInfo = managerInfo;
      this.drivers = data.data || [];
    },
  },
};
</script>

<style lang="scss">
.analytic__wrapper {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
}

.dashboard {
  font-family: SVN-Gilroy;
}
</style>
