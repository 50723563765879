<template>
  <b-table
    :data="data"
    :paginated="isPaginated"
    :per-page="size"
    :loading="loading"
    sort-icon="chevron-up"
    default-sort="user"
    :mobile-cards="false"
  >
    <b-table-column field="rating" cell-class="custom-cell" v-slot="props">
      <div class="rating">
        <img :src="props.row.rating === 'like' ? thumbUpIcon : thumbDownIcon" alt="rating_icon" />

        <span v-if="props.row.rating === 'like'" class="has-text-success">Tốt</span>
        <span v-if="props.row.rating !== 'like'" class="has-text-danger">Chưa tốt</span>
      </div>
    </b-table-column>

    <b-table-column
      sortable
      field="driver"
      default-sort="driver"
      label="Tài xế"
      cell-class="custom-cell"
      v-slot="props"
    >
      <div class="width-150">
        <span @click="navigateProfile(props.row._id)">{{ `${props.row.name} ${!props.row.plate_number ? "" : `- ${props.row.plate_number.split('-')[1] || ''}`}` }}</span>
      </div>
    </b-table-column>

    <b-table-column sortable numeric field="total_orders" label="Số đơn" cell-class="custom-cell start" v-slot="props">
      {{ props.row.total_orders || 0 }}
    </b-table-column>

    <b-table-column sortable numeric field="total_revenue" label="Doanh thu tổng" cell-class="custom-cell start" v-slot="props">
      {{ parseCurrency(props.row.total_revenue || 0) }}
    </b-table-column>

    <b-table-column sortable numeric field="total_income" label="Doanh thu thực nhận" cell-class="custom-cell start" v-slot="props">
      {{ parseCurrency(props.row.total_income || 0) }}
    </b-table-column>

    <b-table-column sortable numeric field="total_income_assigned" label="Doanh thu được gán" cell-class="custom-cell start" v-slot="props">
      {{ parseCurrency(props.row.total_income - props.row.self_total_income || 0) }}
    </b-table-column>

    <template #empty>
      <div class="no-records has-text-centered">No records found</div>
    </template>
  </b-table>
</template>

<script>
import ThumbUpIcon from "@/assets/reporting/thumb-up.png";
import ThumbDownIcon from "@/assets/reporting/thumb-down.png";
import { parseCurrency } from "@/common/utils";
import { mapState } from "vuex";

export default {
  name: "TableComponent",
  props: {
    data: Array,
    isPaginated: Boolean,
    size: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      thumbUpIcon: ThumbUpIcon,
      thumbDownIcon: ThumbDownIcon,
      isEmpty: false,
      isBordered: false,
      isStriped: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState("supplierList", ["supplier_token"]),
  },
  methods: {
    parseCurrency,
    navigateProfile(id) {
      this.$router.push({
        path: `/profile/${id}?supplier_token=${this.supplier_token}`,
      });
    },
  },
};
</script>

<style lang="scss">
.table-wrapper {
  thead th {
    font-size: 14px;
    color: #a2a9b0;
    white-space: nowrap;
  }
  .custom-cell {
    font-size: 14px;
    vertical-align: middle;

    &.start {
      text-align: start !important;
    }
  }
}

.width {
  &-150 {
    width: 150px;
  }
  &-120 {
    width: 120px;
  }
}

.rating {
  max-width: 90px;
  display: flex;
  justify-content: left;
  white-space: nowrap;
  img {
    max-width: 24px;
    max-height: 24px;
    margin-right: 4px;
  }
}

.no-records {
  height: 120px;
}
</style>
