/* eslint-disable */

import { getProfile } from "@/services";
import dsBridge from "dsbridge";
import { mapActions } from "vuex";

export const utils = {
  data: () => ({
    EVENT_HIDE_TOOLBAR: "hide_toolbar",
    EVENT_SHOW_TOOLBAR: "show_toolbar",
    EVENT_CLOSE: "close",
    EVENT_BACK: "back",
    EVENT_PAYMENT: "payment",
    cacheOrderListName: "order-list",
  }),
  methods: {
    ...mapActions([
      "actionUpdateProfile",
      "actionUpdateToken",
      "setAhaOrderId",
    ]),
    postMessage(data) {
      console.log(data);
      let jsonData = JSON.stringify(data);
      if (this.$device.isIOS) {
        this.iosPostMessageHandler(jsonData);
      } else if (this.$device.isAndroid) {
        this.androidPostMessageHandler(jsonData);
      } else {
        this.$sentry &&
          this.$sentry.captureException(
            new Error("Not found device type to emit event"),
          );
        console.log("Not found device type to emit event");
      }
    },
    androidPostMessageHandler(jsonData) {
      try {
        WebJSInterface.requestAction(jsonData);
      } catch (error) {
        this.$sentry && this.$sentry.captureException(error);
        console.log(
          "🚀 ~ file: postMessage.js ~ line 17 ~ AndroidPostMessageHandler ~ error",
          error,
        );
      }
    },
    iosPostMessageHandler(jsonData) {
      try {
        window.webkit.messageHandlers.requestAction.postMessage(jsonData);
      } catch (error) {
        this.$sentry && this.$sentry.captureException(error);
        console.log(
          "🚀 ~ file: postMessage.js ~ line 6 ~ IOSPostMessageHandler ~ error",
          error,
        );
      }
    },
    async getToken() {
      if (dsBridge.hasNativeMethod("getToken"))
        await new Promise((resolve) =>
          dsBridge.call("getToken", "getToken", async (v) => {
            let data = JSON.parse(v);
            console.log("dsBridge-token", data.token);
            await this.actionUpdateToken({ token: data.token });
            resolve();
          }),
        );
    },
    async setUpProfile() {
      if (dsBridge.hasNativeMethod("getToken"))
        await new Promise((resolve) =>
          dsBridge.call("getToken", "getToken", async (v) => {
            let data = JSON.parse(v);
            await this.actionUpdateToken({ token: data.token });
            let profileData = await getProfile(data.token);
            await this.actionUpdateProfile({ profile: profileData });
            resolve();
          }),
        );
    },
    async completeOrder() {
      if (!dsBridge.hasNativeMethod("onActionSuccess")) {
        console.log("CompleteOrder method is not found");
        return;
      }
      await new Promise((resolve) => {
        dsBridge.call("onActionSuccess", "onActionSuccess", async (v) =>
          resolve(v),
        );
      });
    },
    async getAhaOrderInfo() {
      if (dsBridge.hasNativeMethod("getOrderInfo"))
        await new Promise((resolve) => {
          dsBridge.call("getOrderInfo", "getOrderInfo", async (v) => {
            console.log("dsBridge-getOrderInfo", v);
            let data = JSON.parse(v);
            if (data["order_id"])
              this.setAhaOrderId({ ahaOrderId: data["order_id"] });
            resolve();
          });
        });
      else console.log("not found methods");
    },
  },
};
