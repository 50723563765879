<template>
  <div>
    <TopBar
      title="Bộ lọc tìm kiếm"
      :left-icon-path="leftIconPath"
      @leftChildClick="$emit('onClose')"
    />
    <div class="filter-container">
      <div class="filter-container__title">Loại dịch vụ</div>
      <div class="filter-container__box">
        <div v-for="service in services" :key="service.service_id">
          <div @click="onClickService(service.service_id)" class="filter-container__box--text"
            :class="{active: service.state}">
            {{ service.service_name }}
            <img class="filter-container__box--text--selected"
                 :src=SelectIcon alt="ticked" />
          </div>
        </div>
      </div>
      <div class="filter-container__title">Trạng thái</div>
      <div class="filter-container__box">
        <div v-for="status in statuses" :key="status.status"
             @click="onClickStatus(status.status)"
             class="filter-container__box--text"
             :class="{active: status.state}">
          {{ status.status_vi }}
          <img class="filter-container__box--text--selected" :src=SelectIcon alt="ticked" />
        </div>
      </div>
    </div>
    <div class="filter-container__button">
      <button class="btn-cancel btn-cancel__text"
        v-on:click="clearFilter(); $emit('onFilterSelected'); $emit('onClose')">
        Thiết lập lại
      </button>
      <button class="btn-confirm btn-confirm__text"
        v-on:click="setFilter(); $emit('onFilterSelected'); $emit('onClose')">
        Lọc
      </button>
    </div>
  </div>
</template>

<script>
import TopBar from "@/pages/OrderListing/components/TopBar";
import SelectIcon from "@/assets/order-listing/selected-icon.png";
import { mapActions, mapState } from "vuex";

export default {
  name: "FilterSelection",
  components: { TopBar },
  data() {
    return {
      leftIconPath: "order-listing/exit-icon.png",
      SelectIcon,
      isImageModalActive: true,
      services: [
        { service_id: "SGN-PARTNER-METATRUCK", service_name: "SGN-PARTNER-METATRUCK", enable: false, state: false },
        { service_id: "HAN-TRUCK-500", service_name: "HAN-TRUCK-500", enable: false, state: false },
        { service_id: "HAN-TRUCK-1000", service_name: "HAN-TRUCK-1000", enable: false, state: false },
        { service_id: "HAN-PICKUP-TRUCK", service_name: "HAN-PICKUP-TRUCK", enable: false, state: false },
        { service_id: "HAN-VAN-500", service_name: "HAN-VAN-500", enable: false, state: false },
        { service_id: "HAN-VAN-1000", service_name: "HAN-VAN-1000", enable: false, state: false },
        { service_id: "SGN-TRICYCLE", service_name: "SGN-TRICYCLE", enable: false, state: false },
        { service_id: "SGN-TRUCK-500", service_name: "SGN-TRUCK-500", enable: false, state: false },
        { service_id: "SGN-VAN-500", service_name: "SGN-VAN-500", enable: false, state: false },
        { service_id: "SGN-TRUCK-1000", service_name: "SGN-TRUCK-1000", enable: false, state: false },
        { service_id: "SGN-VAN-1000", service_name: "SGN-VAN-1000", enable: false, state: false },
        { service_id: "SGN-TRUCK-1500", service_name: "SGN-TRUCK-1500", enable: false, state: false },
        { service_id: "SGN-TRUCK-2000", service_name: "SGN-TRUCK-2000", enable: false, state: false },
      ],
      statuses: [
        { status: "ASSIGNING", status_vi: "Đang tìm tài xế", state: false },
        { status: "IDLE", status_vi: "Đơn hẹn giờ", state: false }
      ]
    };
  },
  computed: {
    ...mapState({
      supplierServices: state => state.orderList.supplierServices,
      selectedServices: state => state.orderList.selectedServices,
      selectedStatuses: state => state.orderList.selectedStatuses,
      orderServices: state => state.orderList.orderServices,
    }),
  },
  methods: {
    ...mapActions("orderList", [
      "actionSetSelectedServices",
      "actionSetSelectedStatuses"
    ]),
    setFilter() {
      this.actionSetSelectedServices({ value: this.selectedServices });
      this.actionSetSelectedStatuses({ value: this.selectedStatuses });
      console.log("Filter set");
    },
    clearFilter() {
      this.actionSetSelectedServices({ value: [] });
      this.actionSetSelectedStatuses({ value: [] });

    },
    onClickService(id) {
      // const currentSelect = this.services.filter(service => service.service_id === id);
      // currentSelect[0].state = !currentSelect[0].state;
      // this.services = [
      //   ...this.services,
      //   ...currentSelect
      // ]
      for (const service of this.services) {
        if (service.service_id === id) {
          service.state = !service.state;
          if (service.state && this.selectedServices.indexOf(service.service_id) === -1)
            this.selectedServices.push(service.service_id);
          else if (this.selectedServices.indexOf(service.service_id) !== -1)
            this.selectedServices.splice(this.selectedServices.indexOf(service.service_id), 1);
          break;
        }
      }
    },
    onClickStatus(id) {
      for (const status of this.statuses) {
        if (status.status === id) {
          status.state = !status.state;
          if (status.state && this.selectedStatuses.indexOf(status.status) === -1)
            this.selectedStatuses.push(status.status);
          else if (this.selectedStatuses.indexOf(status.status) !== -1)
            this.selectedStatuses.splice(this.selectedStatuses.indexOf(status.status), 1);
          break;
        }
      }
    }
  },
  async mounted() {
    if (this.orderServices && this.orderServices.length > 0) {
      const services = [];
      for (const serviceId of this.orderServices) {
        services.push({
          service_id: serviceId,
          service_name: serviceId,
          enable: true,
          state: false,
        });
      }
      this.services = services;
    }

    if (this.selectedServices && this.selectedServices.length > 0) {
      this.services.forEach(service => {
        if (this.selectedServices.indexOf(service.service_id) > -1) {
          service.state = true;
        }
      });
    }
    if (this.selectedStatuses && this.selectedStatuses.length > 0) {
      this.statuses.forEach(status => {
        if (this.selectedStatuses.indexOf(status.status) > -1) {
          status.state = true;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.filter-container {
  background-color: #FFFFFF;
  z-index: 100;
  padding: 0 16px;
  height: calc(100vh - 56px - 56px);
  position: relative;
  overflow-y: auto;

  &__title {
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #A2A9B0;
    text-align: left;
    padding-top: 13px;
    padding-bottom: 8px;
  }

  &__box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding-right: 8px;
    //margin-right: 8px;

    &--text {
      position: relative;
      background-color: #F2F4F8;
      border: 1px solid #F2F4F8;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 16px;
      margin-right: 8px;
      margin-bottom: 8px;

      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #343A3F;
      transition: ease .5s;

      &.active {
        border: 1px solid #3A86FF;
        transition: ease .5s;
      }

      &--selected {
        height: 12px;
        width: 12px;
        position: absolute;
        top: 1px;
        right: 3px;
        opacity: 0;
        transition: ease .5s;

        .active & {
          opacity: 1;
          transition: ease .5s;
        }
      }
    }
  }

  &__button {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0px;
    position: absolute;
    width: 100%;
    left: 0;
    height: 56px;
    z-index: 101;
    background-color: #FFFFFF;

    .btn {
      &-confirm {
        background: #3A86FF;
        border: 0;
        border-radius: 4px;

        height: 48px;
        width: 48%;
        min-width: 140px;
        padding: 14px 16px;

        &__text {
          font-family: 'SVN-Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;

          color: #FFFFFF;
        }
      }

      &-cancel {
        background: #F2F4F8;
        border: 0;
        border-radius: 4px;

        height: 48px;
        width: 48%;
        min-width: 140px;
        padding: 14px 16px;

        &__text {
          font-family: 'SVN-Gilroy';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;

          color: #343A3F;
        }
      }
    }
  }
}
</style>