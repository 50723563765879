<template>
  <div class="container stock page-wrapper">
    <div class="header blue-header">
      <CustomTopBar :noShadow="true">
        <span class="icon ic-white spin-180" @click="goBackMenu">
          <inline-svg :src="closeIcon"
        /></span>
        <p>Xác nhận đơn hàng</p>
      </CustomTopBar>
    </div>
    <div class="content-box bg-blue">
      <VuePullRefresh :on-refresh="onRefresh" :config="config">
        <div class="card stock-card">
          <div class="heading">
            Vui lòng thông báo cho nhân viên kho thao tác xử lý
          </div>
          <StockItemLoad
            label="Đến Kho/CH"
            btnLabel="Đến nơi"
            msg="Đã đến nơi vào lúc"
            :loading="loading.arrived"
            :isShow="isArrived"
            :time="supplier_arrival_time"
            @clicked="onTruckArrive"
          />

          <StockItemLoad
            v-if="step > 0 || isArrived"
            label="Load/Unload hàng lên/xuống xe"
            btnLabel="Bắt đầu"
            msg="Đã bắt đầu load/unload đơn hàng lúc"
            :loading="loading.start_loaded"
            :isShow="isStartLoaded"
            :time="loading_start_time"
            @clicked="onTruckStartLoad"
          />

          <StockItemLoad
            v-if="step > 1 || isStartLoaded"
            label="Hoàn tất load/unload hàng"
            btnLabel="HOÀN THÀNH"
            msg="Load/Unload hàng thành công lúc"
            :loading="loading.end_loaded"
            :isBtnDisabled="onValidateInfo()"
            :isShow="isEndLoaded"
            :time="loading_complete_time"
            @clicked="onTruckFinishedLoaded"
          >
            <div v-if="!isEndLoaded" class="stack col">
              <input
                v-if="storage === 'DRY'"
                type="number"
                placeholder="Nhập tote"
                v-model="tote"
              />
              <input
                v-if="storage === 'DRY'"
                type="number"
                placeholder="Nhập pallet"
                v-model="pallet"
              />
              <input
                type="number"
                placeholder="Nhập số kiện"
                v-model="parcel"
              />
              <input
                v-if="storage === 'DRY'"
                type="number"
                placeholder="Nhập kiện/công cụ dụng cụ"
                v-model="equipment"
              />
              <input
                v-if="storage === 'FRESH'"
                type="number"
                placeholder="Nhập số rổ"
                v-model="basket"
              />
            </div>
          </StockItemLoad>

          <div v-if="step > 2 || isEndLoaded" class="stack col stock-load">
            <div class="stack col">
              <div class="label field">Xác nhận số seal</div>
              <input
                name="seal_number"
                v-model="seal_number"
                :disabled="isCompleted"
              />
            </div>

            <div v-if="!isPickup" class="stack col">
              <div class="label field">Hàng tại ST trả về</div>
              <input
                type="number"
                placeholder="Nhập tote"
                v-model="return_tote"
                :disabled="isCompleted"
              />
              <input
                type="number"
                placeholder="Nhập số rổ"
                v-model="return_basket"
                :disabled="isCompleted"
              />
              <input
                type="number"
                placeholder="Nhập pallet"
                v-model="return_pallet"
                :disabled="isCompleted"
              />
              <input
                type="number"
                placeholder="Hàng không giao được"
                v-model="return_failed_items"
                :disabled="isCompleted"
              />
              <input
                type="number"
                placeholder="Hàng đổi trả T4&T5"
                v-model="return_changed_items"
                :disabled="isCompleted"
              />
            </div>

            <div class="stack col">
              <div class="label field">Ghi chú</div>
              <input
                name="load_item_note"
                v-model="load_item_note"
                :disabled="isCompleted"
              />
            </div>
          </div>
          <CustomButton
            v-if="step > 2"
            class="btn-orange"
            label="Hoàn tất"
            :loading="loading.complete"
            @clicked="onCompleteOrder"
            :disabled="isDisabledConfirmButton()"
          >
          </CustomButton>
        </div>
      </VuePullRefresh>
    </div>
  </div>
</template>

<script>
import VuePullRefresh from "vue-pull-refresh";
import { format, getUnixTime } from "date-fns";
import { CloseIcon, DoubleCheckIcon, StartIcon } from "@/common/icons";
import CustomTopBar from "@/common/components/CustomTopBar";
import CustomButton from "@/common/components/CustomButton";
import StockItemLoad from "@/pages/Stock/StockItemLoad";
import { fetchLoadingInfo, updateLoadingInfo } from "./services";
import { mapState } from "vuex";
import { REFRESH_CONFIG } from "@/common/constants";
export default {
  name: "Stock",
  components: {
    CustomTopBar,
    CustomButton,
    VuePullRefresh,
    StockItemLoad,
  },
  computed: {
    ...mapState({
      ahaOrderId: (state) => state.ahaOrderId,
    }),
    pathIndex() {
      return this.$route.query.pathIndex ?? "0";
    },
    isPickup() {
      return this.pathIndex === "0";
    },
  },
  created() {
    this.postMessage({ name: this.EVENT_HIDE_TOOLBAR });
  },
  mounted() {
    this.onFetchLoadingInfo();
  },
  data() {
    return {
      config: REFRESH_CONFIG,
      closeIcon: CloseIcon,
      startIcon: StartIcon,
      doubleCheckIcon: DoubleCheckIcon,

      step: 0,
      loading: {
        arrived: false,
        start_loaded: false,
        end_loaded: false,
        complete: false,
      },
      isArrived: false,
      isStartLoaded: false,
      isEndLoaded: false,
      isCompleted: false,

      stockId: "",
      trackingNumber: "",
      loading_complete_time: "",
      loading_start_time: "",
      load_item_note: "",
      seal_number: "",
      return_tote: "",
      return_basket: "",
      return_pallet: "",
      return_failed_items: "",
      return_changed_items: "",
      supplier_arrival_time: "",
      storage: "",
      parcel: "",
      basket: "",
      tote: "",
      pallet: "",
      equipment: "",
    };
  },
  methods: {
    goBackMenu() {
      // TODO Back to Utilities
      this.postMessage({ name: this.EVENT_BACK });
    },
    onRefresh() {
      return new Promise((resolve) => {
        this.onFetchLoadingInfo().then(() => resolve());
      });
    },
    async onFetchLoadingInfo() {
      if (this.ahaOrderId) {
        this.stockId = this.ahaOrderId;
      } else {
        const { orderId: id } = this.$route.query;
        this.stockId = id;
      }

      const params = { orderId: this.stockId, pathIndex: this.pathIndex };

      try {
        const response = await fetchLoadingInfo(params);
        this.initData(response);
      } catch (error) {
        console.log(error);
      }
    },
    initData(response) {
      const {
        loading_complete_time,
        loading_start_time,
        supplier_arrival_time,
        tracking_number,
      } = response;

      this.initStorage(tracking_number); //NOTE: Get warehouse
      this.isArrived = !!supplier_arrival_time; //NOTE: Get arrival time
      this.isStartLoaded = !!loading_start_time; //NOTE: Get start  time loaded
      this.isEndLoaded = !!loading_complete_time; //NOTE: Get end time loaded

      this.loading_start_time = format(
        new Date(loading_start_time * 1000),
        "HH:mm",
      );
      this.supplier_arrival_time = format(
        new Date(supplier_arrival_time * 1000),
        "HH:mm",
      );

      const isOrderLoaded =
        supplier_arrival_time && loading_start_time && loading_complete_time;
      this.handleOrderCompleted(isOrderLoaded, response);
    },
    handleOrderCompleted(isOrderCompleted, response) {
      if (!isOrderCompleted) return;

      const {
        loading_complete_time,
        seal_number,
        load_item_note,
        return_package,
      } = response;

      this.step = 3;
      this.isCompleted = !!seal_number;
      this.seal_number = seal_number;
      this.load_item_note = load_item_note;
      this.return_package = this.onSetReturnPackage(return_package);
      this.loading_complete_time = format(
        new Date(loading_complete_time * 1000),
        "HH:mm",
      );

      if (this.isPickup && this.seal_number) {
        this.completeOrder();
        return;
      }

      this.handleAutoCompleteOrder();
    },
    async handleAutoCompleteOrder() {
      const rt_package = {
        basket: this.return_basket,
        tote: this.return_tote,
        pallet: this.return_pallet,
        failed_items: this.return_failed_items,
        changed_items: this.return_changed_items,
      };
      const isGotEmpty = this.onValidateReturnPackage(rt_package);
      if (!this.seal_number || isGotEmpty) return;

      try {
        await this.completeOrder();
      } catch (error) {
        console.log("error", error);
      }
    },
    onTruckArrive() {
      this.loading.arrived = true;
      const isArriveAt = getUnixTime(new Date());
      const payload = {
        orderId: this.stockId,
        pathIndex: this.pathIndex,
        supplier_arrival_time: isArriveAt,
      };

      updateLoadingInfo(payload)
        .then(() => {
          this.loading.arrived = false;
          this.step += 1;
          this.isArrived = true;
          this.supplier_arrival_time = format(
            new Date(isArriveAt * 1000),
            "HH:mm",
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onTruckStartLoad() {
      this.loading.start_loaded = true;
      const loadingStartTime = getUnixTime(new Date());
      const payload = {
        orderId: this.stockId,
        loading_start_time: loadingStartTime,
        pathIndex: this.pathIndex,
      };

      updateLoadingInfo(payload)
        .then(() => {
          this.step += 1;
          this.loading.start_loaded = false;
          this.isStartLoaded = true;
          this.loading_start_time = format(
            new Date(loadingStartTime * 1000),
            "HH:mm",
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onTruckFinishedLoaded() {
      this.loading.end_loaded = true;
      const loadingEndTime = getUnixTime(new Date());
      const payload = {
        orderId: this.stockId,
        loading_complete_time: loadingEndTime,
        pathIndex: this.pathIndex,
        ...this.onGetParams(),
      };

      updateLoadingInfo(payload)
        .then(() => {
          this.step += 1;
          this.loading.end_loaded = false;
          this.isEndLoaded = true;
          this.loading_complete_time = format(
            new Date(loadingEndTime * 1000),
            "HH:mm",
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async onCompleteOrder() {
      this.loading.complete = true;
      const rtPackage = this.isPickup
        ? {}
        : { return_package: this.getReturnPackage() };

      const payload = {
        orderId: this.stockId,
        seal_number: this.seal_number,
        load_item_note: this.load_item_note,
        pathIndex: this.pathIndex,
        ...rtPackage,
      };

      try {
        await updateLoadingInfo(payload);
        this.loading.complete = false;
        this.isCompleted = true;
        await this.completeOrder();
      } catch (error) {
        console.log(error);
      }
    },
    onValidateInfo() {
      if (this.storage === "DRY") {
        return !this.tote || !this.pallet || !this.parcel || !this.equipment;
      }
      if (this.storage === "FRESH") {
        return !this.parcel || !this.basket;
      }
    },
    onGetParams() {
      const result = {};
      if (this.storage === "DRY") {
        if (this.tote) result.tote = Number(this.tote);
        if (this.pallet) result.pallet = Number(this.pallet);
        if (this.parcel) result.parcel = Number(this.parcel);
        if (this.equipment) result.equipment = Number(this.equipment);
      }
      if (this.storage === "FRESH") {
        if (this.parcel) result.parcel = Number(this.parcel);
        if (this.basket) result.basket = Number(this.basket);
      }
      return result;
    },
    initStorage(tracking) {
      const trackingNumber = this.$route.query.tracking_number;
      const storage = tracking.split("-")[0] ?? trackingNumber.split("-")[0];
      this.storage = storage;
    },
    isDisabledConfirmButton() {
      if (this.isPickup)
        return (
          this.isCompleted ||
          this.loading.complete ||
          !this.isEndLoaded ||
          !this.seal_number
        );

      const isGotEmpty = this.onValidateReturnPackage(this.getReturnPackage());
      return (
        this.isCompleted ||
        this.loading.complete ||
        !this.isEndLoaded ||
        !this.seal_number ||
        isGotEmpty
      );
    },
    onValidateReturnPackage(rt_package) {
      return Object.values(rt_package).some(
        (v) => v === undefined || v === null || v === "",
      );
    },
    getReturnPackage() {
      const rt_package = {
        basket: this.return_basket,
        tote: this.return_tote,
        pallet: this.return_pallet,
        failed_items: this.return_failed_items,
        changed_items: this.return_changed_items,
      };

      return Object.fromEntries(
        Object.entries(rt_package).map(([key, value]) => {
          if (!value) return [key, undefined];
          return [key, Number(value)];
        }),
      );
    },
    onSetReturnPackage(rt_package) {
      if (!rt_package) return "";
      if (typeof rt_package === "string") return rt_package;
      const { tote, basket, pallet, changed_items, failed_items } = rt_package;
      this.return_basket = basket ?? "";
      this.return_tote = tote ?? "";
      this.return_pallet = pallet ?? "";
      this.return_changed_items = changed_items ?? "";
      this.return_failed_items = failed_items ?? "";
    },
    getStorage(tracking) {
      const trackingNumber = this.$route.query.tracking_number;
      const storage = tracking.split("-")[0] ?? trackingNumber.split("-")[0];
      this.storage = storage;
    },
  },
};
</script>

<style lang="scss">
.stock {
  font-family: SVN-Gilroy;
}

.bg-blue {
  background-color: #142246;
}

.header {
  &.blue-header {
    padding: 16px;
    padding-bottom: 0px;
    .top-bar {
      background-color: #142246;
      color: #ffffff;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

svg {
  path {
    .ic-white & {
      fill: #ffffff;
    }
  }
}

.page-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .content-box {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    height: 100vh;
    max-height: 100vh;
    text-align: left;
  }
}
</style>

<style lang="scss" scoped>
.stack {
  display: flex;
  flex-direction: row;
  gap: 4px;
  &.col {
    flex-direction: column;
  }
}

input {
  font-family: SVN-Gilroy;
  border: 1px solid #e5e7e8;
  border-radius: 3px;
  padding: 8px;
  min-height: 40px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.stock-card {
  margin: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  text-align: center;
  margin: 0;
}

.btn-orange {
  background-color: #ff8200;
  width: 100%;
  color: #fff;
  padding: 8px 12px;
}
</style>
