import _ from "lodash";
import client from "@/services/client";
import getUnixTime from "date-fns/getUnixTime";
import startOfDay from "date-fns/startOfDay";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";

export const fetchAsyncSupplierParentStatistic = async (token, from_time, to_time) => {
  from_time = from_time || addDays(startOfDay(new Date()), -7);
  to_time = to_time || endOfDay(new Date());

  const maxToTime = getUnixTime(new Date());
  
  const searchParams = new URLSearchParams();
  searchParams.append("from_time", getUnixTime(from_time));
  searchParams.append("to_time", Math.min(getUnixTime(to_time), maxToTime));

  const res = await client.appApiMetatruck.get(
    `/statistics/parent?${searchParams.toString()}`, {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  
  return res.data;
}
