import client from "@/services/client";

export const getGeoInfo = async (lat, lng) => {
  const res = await client.appApiAhaMovInternal.post(
    `/geocoder/v2/find_admin`,
    {
      point: { lattitude: lat, longitude: lng },
      fields: ["name_vn", "name", "level"],
    },
    { headers: { "Content-Type": "application/json" } }
  );
  return res.data.filter((it) => it.level === 3)[0];
};

const area1 = [
  "Go Vap District",
  "District 1",
  "Binh Tan District",
  "Binh Thanh District",
  "District 10",
  "District 11",
  "District 3",
  "District 4",
  "District 5",
  "District 6",
  "Phu Nhuan District",
  "Tan Binh District",
  "Tan Phu District",
  "Thu Duc District",
];

const area2 = [
  "Can Gio District",
  "Binh Chanh District",
  "Cu Chi District",
  "District 12",
  "District 2",
  "District 7",
  "District 8",
  "District 9",
  "Hoc Mon District",
  "Nha Be District",
];
const area3 = ["Thuan An Town", "Di An Town", "Thu Dau Mot City"];
const area4 = [
  "Bien Hoa City",
  "Long Thanh District",
  "Nhon Trach District",
  "Trang Bom District",
  "Long An Province"
];

export { area1, area2, area3, area4 };
