<template>
  <div class="report-component section-box">
    <div class="text">
      <p class="name">{{ driver.name }}</p>
      <p class="plate">{{ driver.plate_number || "Chưa cập nhật"}}</p>
    </div>

    <BoardWrapper>
      <ScoreComponent label="Mức đánh giá" :value="rating" star />
      <ScoreComponent
        label="Tỷ lệ chấp nhận"
        key-name="acceptance_rate"
        :value="acceptance_rate"
        :tooltipData="getARMessage(acceptance_rate)"
      />
      <ScoreComponent
        label="Tỷ lệ hủy đơn"
        key-name="cancellation_rate"
        :value="cancellation_rate"
        :tooltipData="getCRMessage(cancellation_rate)"
      />
    </BoardWrapper>

    <b-carousel-list
      class="chart-carousel no-shadow"
      v-model="carousel"
      :data="items"
      :items-to-show="1"
      :animated="animated"
      :autoplay="autoPlay"
      :arrow-hover="false"
      :repeat="repeat"
    >
      <template #item="list">
        <ChartItem :data="list" />
      </template>
    </b-carousel-list>

    <div class="bottom-text">ĐƠN THEO NGÀY</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, endOfWeek, getDay, addMonths } from "date-fns";
import vi from "date-fns/locale/vi";
import BoardWrapper from "@/common/components/Chart/BoardWrapper";
import ScoreComponent from "@/common/components/Chart/ScoreComponent";
import ChartItem from "@/common/components/Chart/ChartItem";
import { fetchAsyncDriverStatistics } from "@/pages/Profile/services/driverService";
import startOfWeek from 'date-fns/startOfWeek';

export default {
  name: "ReportComponent",
  components: { BoardWrapper, ScoreComponent, ChartItem },
  computed: {
    ...mapGetters({
      detail: "managementStore/getDriverDetail",
    }),
    rating() {
      const val = this.items[0]?.rating ?? 0;
      return Math.round(val * 100) / 100;
    },
    acceptance_rate() {
      const val = this.items[0]?.acceptance_rate ?? 0;
      return Math.round(val * 100) / 100;
    },
    cancellation_rate() {
      const val = this.items[0]?.cancellation_rate ?? 0;
      return Math.round(val * 100) / 100;
    }
  },
  async mounted() {
    const { driverId } = this.$route.params;
    const { supplier_token } = this.$route.query;

    await this.getDriverDetail({
      token: supplier_token,
      supplier_id: driverId,
    });

    this.driver = this.detail[driverId]

    if (driverId) {
      const data = await fetchAsyncDriverStatistics(supplier_token, driverId);
      this.items = this.mapData(data);
      this.carousel = this.items.length - 1;
    }
  },
  data() {
    return {
      driver: '',
      carousel: 0,
      animated: "slide",
      autoPlay: false,
      indicator: false,
      repeat: false,
      scoreData: "",
      items: [],
    };
  },
  methods: {
    ...mapActions({
      getDriverDetail: "managementStore/fetchDriverDetail",
    }),
    mapData(data) {
      const week_stats = [];
      for (const daily_stat of data.daily_stats) {
        const startOfThisWeek = startOfWeek(new Date(daily_stat.time_range), { weekStartsOn: 1 });
        let indexWeek = week_stats.findIndex(
          (item) => item.period.getTime() === startOfThisWeek.getTime()
        );

        if (indexWeek === -1) {
          indexWeek = week_stats.push({
            period: startOfThisWeek,
            periodString: this.getPeriodTitle(startOfThisWeek),
            acceptance_rate: data.acceptance_rate,
            cancellation_rate: data.cancellation_rate,
            rating: data.rating,
            online_time: 0,
            totalFee: 0,
            totalOrder: 0,
            chartData: [],
          }) - 1;
        }

        const week_stat = week_stats[indexWeek];

        week_stat.totalFee += daily_stat.revenue;
        week_stat.totalOrder += daily_stat.orders;
        week_stat.online_time += daily_stat.online_time;
        week_stat.active_time = this.getOnlineTime(week_stat.online_time / 3600);

        const dateIndex = getDay(new Date(daily_stat.time_range));
        const label = this.convertKeyDate(dateIndex);
        week_stat.chartData.push({
          dateIndex,
          label,
          period: daily_stat.period,
          total_order: daily_stat.orders,
        });

        week_stats[indexWeek] = week_stat;
      }

      return week_stats;
    },
    getPeriodTitle(dateObj) {
      const firstMonth = format(dateObj, "MM", { locale: vi });
      const firstDay = format(dateObj, "dd");
      const nextMonthObj = addMonths(dateObj, 1);
      const nextMonth = format(nextMonthObj, "MM", { locale: vi });

      const lastDay = format(
        new Date(endOfWeek(dateObj, { weekStartsOn: 1 })),
        "dd"
      );

      if (parseInt(firstDay) > parseInt(lastDay)) {
        return `Doanh thu ${firstDay}/${firstMonth} - ${lastDay}/${nextMonth}`;
      } else {
        return `Doanh thu ${firstDay}/${firstMonth} - ${lastDay}/${firstMonth}`;
      }
    },
    getOnlineTime(active_time) {
      if (active_time === 0) {
        return 0;
      } else if (active_time < 1) {
        const minute = Math.floor(active_time * 60);
        return `${minute}'`;
      } else {
        const hour = Math.floor(active_time);
        const minute = Math.floor((active_time - hour) * 60);
        return `${hour}h${minute.toString().padStart(2, "0")}'`;
      }
    },
    getARMessage(score) {
      if (score < 0.9) {
        return "Cần cải thiện trên 90%";
      }

      return "Tốt";
    },
    getCRMessage(score) {
      if (score > 0.3) {
        return "Cần cải thiện dưới 30%";
      }

      return "Tốt";
    },
    convertKeyDate(index) {
      switch (index) {
        case 0:
          return "SU";
        case 1:
          return "MO";
        case 2:
          return "TU";
        case 3:
          return "WE";
        case 4:
          return "TH";
        case 5:
          return "FR";
        case 6:
          return "SA";
        default:
          return "";
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.name {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #121619;
  margin-bottom: 4px;
}
.plate {
  font-size: 16px;
  line-height: 24px;
  color: #4d5358;
  margin-bottom: 8px;
}

.section-box {
  padding: 16px;
}

.no-shadow {
  box-shadow: none;
}
</style>
