<template>
  <div v-if="!iframeUrl">Not found</div>
  <iframe v-else ref="iframeRef" style="width: 100vw; height: calc(100vh - 10px);" seamless
    allow="geolocation; clipboard-read; clipboard-write"
    sandbox="allow-downloads allow-same-origin allow-scripts allow-forms allow-modals allow-pointer-lock allow-popups allow-orientation-lock"
    :src="iframeSrc"
    >
    </iframe>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EmbedIframe",
  data() {
    return {
      iframeRef: null,
      iframeUrl: this.$route.params.iframe_embed_url,
      iframeSrc: '',
      embedUrl: process.env.VUE_APP_EMBED_URL,
    };
  },
  computed: {
    ...mapState(['token'])
  },
  methods: {
    handleMessage(ev) {
      console.log('[embed-actions] handleMessage', ev);
      const url = new URL(this.iframeUrl, this.embedUrl);
      if (ev.origin !== url.origin) return;

      const data = ev.data;
      if (data?.action === 'CHILD_IFRAME_CHANGE') {
        this.handleIframeLocationChanged(data?.pathname || '', data?.search || '');
      } else if (data?.action === 'CHILD_IFRAME_NOTIFY_PARENT_BACK') {
        this.$router.go(-1);
      }
    },
    handleIframeLocationChanged(pathname, search) {
      const currentURL = new URL(window.location.href);
      const searchParams = new URLSearchParams(currentURL.searchParams.toString());
      if (searchParams.get('child_search') !== search) {
        searchParams.set('child_search', search);
      };
      if (searchParams.get('child_pathname') !== pathname) {
        searchParams.set('child_pathname', pathname);
      };
      if (searchParams.toString() !== currentURL.searchParams.toString()) {
        const newQuery = {};
        for (const [key, value] of searchParams) {
          newQuery[key] = value;
        }
        this.$router.replace({ query: newQuery });
      }
    }
  },
  created() {
    this.postMessage({
      name: this.EVENT_HIDE_TOOLBAR,
    });
  },
  mounted() {
    const url = new URL(this.iframeUrl, this.embedUrl);
    const child_pathname = this.$route.query['child_pathname'];

    if (child_pathname) {
      url.pathname = child_pathname;
    }

    const child_search = this.$route.query['child_search'];
    if (child_search) {
      url.search = child_search;
    }

    const supplier_token = this.token || this.$route.query.supplier_token;

    url.searchParams.set('protocol', 'gadget');
    url.searchParams.set('supplier_token', supplier_token);

    this.iframeSrc = url.toString();

    window.addEventListener('message', this.handleMessage);
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage);
    this.iframeRef = null;
  },
}
</script>
