import client from "@/services/client";

export async function getOrderInfo(admin_token, order_id) {
  // console.log({admin_token, order_id});
  const res = await client.appApiAhamove.get(`/order/detail/${order_id}`, {
    headers: {
      Authorization: `Bearer ${admin_token}`,
    },
  });
  // console.log(JSON.stringify(res.data));
  return res.data;
}

export async function assignOrder(admin_token, order_id, supplier_id) {
  // console.log({ admin_token, order_id, supplier_id });

  try {
    const res = await client.appApiAhamove.put(
      `/order/assign`,
      {
        order_id,
        supplier_id,
      },
      {
        headers: {
          Authorization: `Bearer ${admin_token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return { status: res.status };
  } catch (err) {
    return { status: err.response.status, message: err.response.data.title };
  }
}

export async function getSupplierOrder(
  supplier_token,
  supplier_id,
  service_ids
) {
  try {
    let queryParams = [];
    if (service_ids && service_ids.length > 0)
      service_ids.forEach((service_id) =>
        queryParams.push(`service_id=${service_id}`)
      );

    const res = await client.appApiOnwheelUtility.get(
      `orders/${supplier_id}/list${
        queryParams.length > 0 ? "?" + queryParams.join("&") : ""
      }`,
      {
        headers: {
          token: supplier_token,
        },
      }
    );

    return res.data.data;
  } catch (err) {
    console.log(`getSupplierOrder err -> ${err.toString()}`);
    throw err;
  }
}

export function isMetatruckOrder(order_info) {
  return order_info.service_id.endsWith("METATRUCK");
}
