<template>
  <div>
    <div class="listing-container" :class="{ empty: suppliers.length === 0 }">
      <div class="header-container">
        <HeaderNew @refreshSupplier="reloadSuppliers">
          <div class="search-box-wrapper">
            <b-field>
              <b-input
                  placeholder="Tìm kiếm theo tên, biển số xe"
                  type="search"
                  icon-pack="fas"
                  icon="search"
                  name="search_value"
                  @input="onSearch"
              />
            </b-field>
          </div>
        </HeaderNew>
      </div>

      <div class="content-container" v-if="suppliers" :class="{ empty: suppliers.length === 0 }">
        <b-notification v-if="isLoadingSupplier" :closable="false">
          <b-loading :is-full-page="false" v-model="isLoadingSupplier" :can-cancel="true"></b-loading>
        </b-notification>
        <TableNew :suppliers="suppliers" :loading="isLoadingSupplier" />
      </div>
      <div class="modal-container" v-show="isShowConfirmModal">
        <ModalNew />
      </div>
      <div class="modal-container" v-show="isShowCallerModal">
        <CallerModal />
      </div>
    </div>
    <div class="toast-container" v-show="isShowToast.enable">
      <ToastNew :type="isShowToast.type" :message="isShowToast.message" />
    </div>
  </div>
</template>

<script>
import { filterClient } from "@/common/utils";
import CallerModal from "@/pages/SupplierListing/components/ListingNew/CallerModal";
import HeaderNew from "@/pages/SupplierListing/components/ListingNew/HeaderNew";
import ModalNew from "@/pages/SupplierListing/components/ListingNew/ModalNew";
import TableNew from "@/pages/SupplierListing/components/ListingNew/TableNew";
import ToastNew from "@/pages/SupplierListing/components/ListingNew/ToastNew";
import { area1, area2, area3, area4, getGeoInfo } from "@/pages/SupplierListing/services/geoService";
import { getOrderInfo, isMetatruckOrder } from "@/pages/SupplierListing/services/orderService";
import {
  getAdminToken,
  getFavoriteSuppliersInfo,
  getOsrmDistance,
  getSameRouteScore,
  getSupplierInfo,
} from "@/pages/SupplierListing/services/supplierService";
import moment from "moment";
import "moment/locale/vi";
import { mapActions, mapState } from "vuex";

export default {
  name: "SupplierListingNew",
  components: { CallerModal, ToastNew, ModalNew, HeaderNew, TableNew },
  data() {
    return {
      // suppliers: [{"name":"sâm","vehicle":"MOTORBIKE","coordinates":[106.66372169025412,10.769504089848335],"last_activity":"vài giây trước","status":"ONLINE","_id":"84337764241","services":["SGN-BIKE","SGN-PARTNER-AHAMOVE","VNM-PARTNER-KAMEGOMI","VNM-PARTNER-AHAMOVE","VNM-PARTNER-KAMEGOMI-PICKUP"],"plate_number":"59P1 - 9808","avatar":"https://ahamove-document.s3.ap-southeast-1.amazonaws.com/production/84337764241_avatar_c1c324ca-0c26-4567-956e-ea6427d57a09.png","supplier":"sâm   - 59P1 - 9808 59P1 - 9808","distance":"3 km"},{"name":"sâm","vehicle":"MOTORBIKE","coordinates":[106.66371769476889,10.76950854880746],"last_activity":"20 giờ trước","status":"BUSY","_id":"84935095650","services":["SGN-BIKE","SGN-PARTNER-AHAMOVE","SGN-TRUCK-500","SGN-PARTNER-METATRUCK"],"plate_number":"76V2-77777","avatar":"https://apistg.ahamove.com/v1/supplier/file?file_id=avatar&id=84935095650&size=128&version=2","supplier":"sâm   - 76V2-77777 76V2-77777","distance":"3 km"}],
      suppliers: [],
      loadedSuppliers: [],
      assign_error: false,
      error_message: "",
      isLoadingSupplier: true,
      // isShowToast: { type: 'failed', enable: true }
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      admin_token: (state) => state.supplierList.admin_token,
      supplier_token: (state) => state.supplierList.supplier_token,
      ahaOrderId: (state) => state.ahaOrderId,
      isShowConfirmModal: (state) => state.supplierList.show_confirm_modal,
      isShowCallerModal: (state) => state.supplierList.show_caller_modal,
      isOrderAssigned: (state) => state.supplierList.order_assigned,
      isShowToast: (state) => state.supplierList.show_toast,
    }),
  },
  methods: {
    ...mapActions("supplierList", [
      "actionSetOrderId",
      "actionSetSupplierToken",
      "actionSetAdminToken",
      "actionSetUserId",
      "actionSetOrderService",
      "actionSetMasterSupplierId",
      "actionSetShowToast",
      "actionSetOpenInApp",
    ]),
    build_supplier(item) {
      let words = item.name.split(" "),
          name = "",
          string = "",
          i = 0;
      do {
        i += 1;
        string = words.pop();
        if (string) name = `${string} ${name}`;
        else break;
      } while (i < 2);
      let plate_numbers = item.plate_number.split(".");
      return `${name} ${item.plate_number ? " - " + item.plate_number /*.split("-")[1]*/ : ""} ${plate_numbers.join(
          ""
      )}`;
    },
    onSearch(query) {
      if (query) {
        this.suppliers = filterClient(query, this.loadedSuppliers, "supplier");
      } else {
        this.suppliers = this.loadedSuppliers;
      }
    },
    async getSameRouteScoreBySuppliers(order_id, suppliers, supplier_token) {
      console.log("getSameRouteScoreBySuppliers", suppliers);
      for (let supplier of suppliers) {
        try {
          supplier.same_route_score = await getSameRouteScore(
              order_id,
              supplier._id,
              supplier_token,
              supplier.coordinates[0],
              supplier.coordinates[1]
          );
        } catch (error) {
          console.log(`getSameRouteScoreBySuppliers: ${error}`);
        }
      }
      return suppliers;
    },
    async getOsrmDistanceBySupplier(order_coordinates, suppliers) {
      const order_lat = order_coordinates[1],
          order_lng = order_coordinates[0];

      let sup_coords = suppliers.map((sup) => [sup.coordinates[1], sup.coordinates[0]]);
      return await getOsrmDistance(sup_coords, order_lat, order_lng);
    },
    async loadSuppliers () {
      if (this.ahaOrderId && this.token) this.actionSetOpenInApp({ value: true });
      const order_id = this.ahaOrderId || this.$route.query.order_id;
      const supplier_token =
          (this.supplier_token.length > 0 && this.supplier_token) || this.token || this.$route.query.supplier_token;
      this.actionSetOrderId({ value: order_id });
      this.actionSetSupplierToken({ value: supplier_token });
      // console.log(`SupplierListingNew: order_id -> ${order_id}`);

      if (this.admin_token === "")
        try {
          const new_admin_token = await getAdminToken(supplier_token);
          this.actionSetAdminToken({ value: new_admin_token });
          // console.log(`SupplierListingNew: admin_token -> ${admin_token}`);
        } catch (error) {
          this.assign_error = true;
          this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! ${error.response.status}: ${error.response.data.description}`;
          this.actionSetShowToast({
            value: { type: "failed", message: this.error_message, enable: true },
          });

          this.isLoadingSupplier = false;
          return setTimeout(() => {
            this.actionSetShowToast({ value: { enable: false } });
            this.postMessage({
              name: this.EVENT_CLOSE,
            });
          }, 5000);
        }

      let order_info = null;
      try {
        order_info = await getOrderInfo(this.admin_token, order_id);
        this.actionSetUserId({ value: order_info.user_id });
        this.actionSetOrderService({ value: order_info.service_id });
        console.log(`SupplierListingNew: order_id -> ${order_info._id}`);
      } catch (error) {
        this.assign_error = true;
        this.error_message = `Đã xảy ra lỗi! ${error.response.status}: ${error.response.data.title}`;
        this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });

        this.isLoadingSupplier = false;
        return setTimeout(() => {
          this.actionSetShowToast({ value: { enable: false } });
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 3000);
      }

      if (["IDLE", "ASSIGNING"].indexOf(order_info.status) === -1) {
        this.assign_error = true;
        this.error_message = `Đơn hàng không thể gán. Đã có tài xế chấp nhận.`;
        this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });

        this.isLoadingSupplier = false;
        return setTimeout(() => {
          this.actionSetShowToast({ value: { enable: false } });
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 3000);
      }

      const order_lat = order_info.from_location.coordinates[1],
          order_lng = order_info.from_location.coordinates[0];
      const order_coordinates = [order_lng, order_lat];
      let geoInfo = null,
          available_range = null;
      try {
        geoInfo = await getGeoInfo(order_coordinates[1], order_coordinates[0]);
      } catch (error) {
        this.error_message = `Đã xảy ra lỗi lấy geo đơn hàng! ${error.response.status}: ${error.response.data.description}`;
      }
      if (["SGN-BULKY-TRUCK-INTERNAL", "HAN-BULKY-TRUCK-INTERNAL"].includes(order_info.service_id)) {
        // Specific for order with service SGN-BULKY-TRUCK-INTERNAL / HAN-BULKY-TRUCK-INTERNAL
        available_range = 15.999;
      } else if (geoInfo) {
        if (area1.includes(geoInfo.name)) {
          if (isMetatruckOrder(order_info)) {
            const now = moment();
            const morningLimit = moment(now).hour(8).minute(0).second(0);
            const eveningLimit = moment(now).hour(19).minute(0).second(0);
            if (now.isBefore(morningLimit) || now.isAfter(eveningLimit)) {
              // Specific for Metatruck on time 00:00 -> 8:00 AND 19:00 -> 24:00
              available_range = 10.999;
            }
          } else {
            available_range = 6.999;
          }
        } else if (area2.includes(geoInfo.name)) {
          available_range = 10.999;
        } else if (area3.includes(geoInfo.name)) {
          available_range = 15.999;
        } else if (area4.includes(geoInfo.name)) {
          available_range = 20.999;
        } else {
          available_range = 30.999;
        }
      }
      else {
        available_range = 6.999;
      }

      let supplier_info = null;
      let supplier_id = null;
      try {
        supplier_info = await getSupplierInfo(supplier_token);
        supplier_id = supplier_info.supplier._id;
        this.actionSetMasterSupplierId({ value: supplier_id });
        console.log(`SupplierListingNew: supplier_id -> ${supplier_id}`);
      } catch (error) {
        this.assign_error = true;
        this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! ${error.response.status}: ${error.response.data.description}`;
        this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });

        this.isLoadingSupplier = false;
        return setTimeout(() => {
          this.actionSetShowToast({ value: { enable: false } });
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 3000);
      }

      let favorite_suppliers_info = null;
      try {
        favorite_suppliers_info = await getFavoriteSuppliersInfo(supplier_token, supplier_id);

        favorite_suppliers_info = favorite_suppliers_info.filter(
            (item) => item.services.indexOf(order_info.service_id) > -1 && ["ONLINE", "BUSY"].indexOf(item.status) > -1
        );

        if (favorite_suppliers_info.length === 0) {
          this.isLoadingSupplier = false;
          this.assign_error = true;
          this.error_message = `Không có tài xế khả dụng để gán đơn!`;
          this.actionSetShowToast({
            value: { type: "failed", message: this.error_message, enable: true },
          });
          return setTimeout(() => {
            this.actionSetShowToast({ value: { enable: false } });
          }, 3000);
        }

        const osrm_distance_list = await this.getOsrmDistanceBySupplier(order_coordinates, favorite_suppliers_info);
        for (let i = 0; i < favorite_suppliers_info.length; i++) {
          favorite_suppliers_info[i].osrm_distance = osrm_distance_list[i];
        }

        for (let item of favorite_suppliers_info) {
          item.supplier = this.build_supplier(item);
          item.last_activity = moment.unix(Math.round(Number(item.last_activity))).fromNow();
          item.distance =
              (Math.round((item.osrm_distance / 1000) * 100) / 100).toFixed(2) ||
              Math.sqrt(
                  (item.coordinates[0] - order_coordinates[0]) ** 2 + (item.coordinates[1] - order_coordinates[1]) ** 2
              ) * 111.2;
          item.distanceText = `${
              item.distance >= 1 ? item.distance.toString() + " km" : Math.round(item.distance * 1000) + " m"
          }`;
          if (available_range) item.is_in_range = item.distance < available_range;

          // check if order.service_id contains string like "VNM" like in "VNM-PARTNER-KAMEGOMI"
          const regexStr = new RegExp(`VNM`);
          if (order_info.service_id.match(regexStr)) {
            item.is_in_range = true;
          }

        }

        favorite_suppliers_info.sort((a, b) => {
          if (Number(a.distance) < Number(b.distance)) return -1;
          if (Number(a.distance) > Number(b.distance)) return 1;
          return 0;
        });

        this.suppliers = favorite_suppliers_info;
        this.loadedSuppliers = favorite_suppliers_info;
        this.isLoadingSupplier = false;
      } catch (error) {
        this.assign_error = true;
        this.error_message = `Đã xảy ra lỗi! ${error.response?.status || 500}: ${
            error.response?.data?.description || "Internal Server Error"
        }`;
        this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });

        this.isLoadingSupplier = false;
        return setTimeout(() => {
          this.actionSetShowToast({ value: { enable: false } });
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 3000);
      }
    },
    reloadSuppliers() {
      console.log('click refresh');
      this.isLoadingSupplier = true;
      this.loadSuppliers();
    },
  },
  created() {
    this.postMessage({
      name: this.EVENT_HIDE_TOOLBAR,
    });
  },
  async mounted() {
    await this.loadSuppliers();
  },
};
</script>

<style lang="scss">
.listing-container {
  padding-bottom: 24px;
  font-family: SVN-Gilroy;
  height: 100vh;
  overflow-y: scroll;
  background: #f2f4f8;

  &.empty {
    background-color: #ffffff;
  }

  .header-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }

  .search-box-wrapper {
    padding: 0 16px 16px;
    margin-top: 16px;
  }
}

.modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 15;

  background: rgba(18, 22, 25, 0.25);
}

.toast-container {
  padding: 0 16px 8px;
  position: fixed;
  width: 100vw;
  height: auto;
  bottom: 16px;
  z-index: 20;
}

.content-container {
  position: relative;
  height: calc(100% - 168px);

  &.empty {
    background-color: #ffffff;
  }

  .notification {
    position: absolute;
    height: 100%;
    min-height: 100px;
    width: 100%;
  }
}
</style>
