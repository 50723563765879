<template>
  <div class="listing-container">
    <div class="header-container">
      <TopBar
        title="Điều đơn"
        :left-icon-path="backIconPath"
        @leftChildClick="goBack"
        :right-icon-path="filterIconPath"
        @rightChildClick="openFilter"
        :refresh-icon-path="refreshIconPath"
        @refreshChildClick="refreshOrder"
      />
      <div class="search-box-wrapper">
        <b-field>
          <b-input
            placeholder="Tìm kiếm theo địa chỉ"
            type="search"
            icon-pack="fas"
            icon="search"
            name="search_value"
            @input="onSearch"
          />
        </b-field>
      </div>
    </div>
    <b-modal v-model="showFilter" has-modal-card full-screen scroll="keep" :can-cancel="false">
      <FilterSelection @onClose="closeFilter" @onFilterSelected="filterOrder" />
    </b-modal>
    <div class="content-container">
      <b-notification v-if="isLoadingOrder" :closable="false">
        <b-loading :is-full-page="false" v-model="isLoadingOrder" :can-cancel="true"></b-loading>
      </b-notification>
      <OrderTable :orders="orders" :loading="isLoadingOrder" />
    </div>
    <div class="toast-container" v-show="isShowToast.enable">
      <ToastNew :type="isShowToast.type" :message="isShowToast.message" />
    </div>
  </div>
</template>

<script>
import { filterClient } from "@/common/utils";
import OrderTable from "@/pages/OrderListing/components/OrderTable";
import TopBar from "@/pages/OrderListing/components/TopBar";
import { getOrderListing } from "@/pages/OrderListing/services/orderService";
import FilterSelection from "@/pages/OrderListing/views/FilterSelection";
import ToastNew from "@/pages/SupplierListing/components/ListingNew/ToastNew";
import { getSupplierInfo } from "@/pages/SupplierListing/services/supplierService";
import router from "@/router";
import { mapActions, mapState } from "vuex";

export default {
  name: "OrderListing",
  components: { FilterSelection, OrderTable, TopBar, ToastNew },
  data() {
    return {
      backIconPath: "order-listing/left-arrow.png",
      filterIconPath: "order-listing/filter-icon.png",
      refreshIconPath: "order-listing/refresh-icon.png",
      orders: [],
      loadedOrders: [],
      isLoadingOrder: true,
      loadingError: false,
      showFilter: false,
    };
  },
  computed: {
    ...mapState({
      isShowToast: (state) => state.supplierList.show_toast,
      selectedServices: (state) => state.orderList.selectedServices,
      selectedStatuses: (state) => state.orderList.selectedStatuses,
    }),
    ...mapState("supplierList", ["supplier_id", "master_supplier_id", "supplier_token"]),
  },
  methods: {
    ...mapActions({
      actionSetMasterSupplierId: "supplierList/actionSetMasterSupplierId",
      actionSetSupplierId: "supplierList/actionSetSupplierId",
      actionSetSupplierToken: "supplierList/actionSetSupplierToken",
      actionSetAdminToken: "supplierList/actionSetAdminToken",
      actionSetShowToast: "supplierList/actionSetShowToast",
      actionSetSupplierServices: "orderList/actionSetSupplierServices",
      actionSetOrderServices: "orderList/actionSetOrderServices",
    }),
    goBack() {
      router.go(-1);
      // this.postMessage({ name: this.EVENT_BACK });
    },
    onSearch(query) {
      if (query) {
        this.orders = filterClient(query, this.loadedOrders, "info");
      } else {
        this.orders = this.loadedOrders;
      }
    },
    openFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    filterOrder() {
      let filteredOrders = Array.from(this.loadedOrders);
      if (this.selectedServices && this.selectedServices.length > 0) {
        filteredOrders = filteredOrders.filter((order) => this.selectedServices.indexOf(order.service_id) > -1);
      }
      if (this.selectedStatuses && this.selectedStatuses.length > 0) {
        filteredOrders = filteredOrders.filter((order) => this.selectedStatuses.indexOf(order.status) > -1);
      }
      // this.filterEnabled = !(this.selectedServices.length === 0 && this.selectedStatuses.length === 0);
      this.orders = filteredOrders;
    },
    async refreshOrder() {
      this.isLoadingOrder = true;
      await this.loadOrders();
      await this.filterOrder();
    },
    async loadOrders() {
      try {
        const orders = await getOrderListing(this.supplier_token, this.master_supplier_id);
        if (!orders || orders.length === 0) {
          this.isLoadingOrder = false;
          this.loadingError = true;
          this.error_message = `Không có đơn khả dụng để gán. Vui lòng kiểm tra lại sau!`;
          await this.actionSetShowToast({
            value: { type: "failed", message: this.error_message, enable: true },
          });
          return setTimeout(() => {
            this.actionSetShowToast({ value: { enable: false } });
          }, 3000);
        }
        orders.map((order) => (order.info = `${order.pick_up || ""}`));
        this.orders = orders;
        this.isLoadingOrder = false;
        this.loadedOrders = orders;
        const orderServices = [...new Set(orders.map((order) => order.service_id))];
        await this.actionSetOrderServices({ 
          value: orderServices
        });
      } catch (error) {
        console.log({ error });
        this.loadingError = true;
        this.error_message = `Đã xảy ra lỗi! ${error.response.status}: ${error.response.data.description}`;
        await this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });
        this.isLoadingOrder = false;
        return setTimeout(() => {
          this.actionSetShowToast({ value: { enable: false } });
          this.postMessage({
            name: this.EVENT_CLOSE,
          });
        }, 3000);
      }
    },
  },
  created() {
    this.postMessage({
      name: this.EVENT_HIDE_TOOLBAR,
    });
  },
  async mounted() {
    const supplier_token = this.$route.query.supplier_token;
    // console.log({ supplier_token });
    await this.actionSetSupplierToken({ value: supplier_token });

    let supplier_info = null,
      supplier_id = null;
    try {
      supplier_info = await getSupplierInfo(supplier_token);
      supplier_id = supplier_info.supplier._id;
      await this.actionSetMasterSupplierId({ value: supplier_id });
      if (!supplier_info?.supplier?.services && supplier_info.supplier.services.length === 0) {
        this.loadingError = true;
        this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! 404: Chủ xe chưa có dịch vụ kích hoạt`;
        await this.actionSetShowToast({
          value: { type: "failed", message: this.error_message, enable: true },
        });
        return setTimeout(() => {
          this.actionSetShowToast({ value: { enable: false } });
        }, 3000);
      }
      await this.actionSetSupplierServices({
        value: supplier_info.supplier.services,
      });
    } catch (error) {
      console.log({ error });
      this.loadingError = true;
      this.error_message = `Đã xảy ra lỗi, xin vui lòng thử lại! ${error.response.status}: ${error.response.data.description}`;
      await this.actionSetShowToast({
        value: { type: "failed", message: this.error_message, enable: true },
      });
      this.isLoadingOrder = false;
      return setTimeout(() => {
        this.actionSetShowToast({ value: { enable: false } });
        this.postMessage({
          name: this.EVENT_CLOSE,
        });
      }, 3000);
    }

    await this.loadOrders();
  },
};
</script>

<style lang="scss" scoped>
.listing-container {
  padding-bottom: 24px;
  font-family: "SVN-Gilroy";
  height: 100vh;
  background: #ffffff;

  .header-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }

  .search-box-wrapper {
    padding: 0 16px 13px;
    margin-top: 16px;
  }
}

.content-container {
  position: relative;
  height: calc(100% - 112px);
  background-color: #ffffff;
  padding-top: 1px;

  .notification {
    position: absolute;
    height: 100%;
    min-height: 100px;
    width: 100%;
  }
}

.toast-container {
  padding: 0 16px 8px;
  position: fixed;
  width: 100vw;
  height: auto;
  bottom: 16px;
  z-index: 20;
}
</style>
